import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FeatureObjectTypes from "../components/feature-object-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faArrowLeft,
  faImagePortrait,
  faBox,
  faCode,
  faMicrochip,
  faDatabase,
  faInfoCircle,
  faSquare,
  faCog,
} from "@fortawesome/free-solid-svg-icons"
import FeatureCard from "../components/feature-card"
import ActionForm from "../components/action-form"
import { JobFeature } from "../components/job-components"
import FeatureContainerApi from "../components/feature-container-api"
import FeatureMetadataMasks from "../components/feature-metadata-masks"
import SuccessStoryCard from "../components/success-story-card"
import Customers from "../components/customers"
import FeatureTcpNumpy from "../components/feature-tcp-numpy"
import { faLinux } from "@fortawesome/free-brands-svg-icons"

const ProductContainer = ({ data }) => (
  <Layout>
    <SEO
      title="Image and Video Anonymization Docker Container - Protect Privacy with Celantur"
      description="Blur faces, persons, vehicles and license plates on images and videos with Celantur Container (Docker). Deploy on-premise and in your cloud."
    />

    <section className="container pt-3 pt-md-5 pb-3 pb-sm-3">
      <div className="row align-items-center">
        <div className="col-12 cold-md-5 col-lg-6 order-md-2">
          <Img fluid={data.headerImage.childImageSharp.fluid} />
        </div>
        <div className="col-12 cold-md-7 col-lg-6 order-md-1">
          <h1 className="display-4 text-center text-md-left text-primary font-weight-bold">Celantur Container</h1>
          <p className="lead text-center text-md-left mb-5">
            <strong>Fully-automated image and video blurring software.</strong> Deploy highly scalable into your
            on-premise and cloud data workflows.
          </p>
          <div className="text-center text-md-left">
            <Link to="/contact/" className="btn btn-primary lift event-start-demo">
              Request Demo <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
            </Link>
            <p className="text-muted font-size-sm mt-1">
              You'll get a time-limited PRO license, containing all features
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <ul className="list-unstyled">
            <li>
              <JobFeature>
                <strong>Image & Video Blurring:</strong> Automatically blur faces, persons, license plates, vehicles.
              </JobFeature>
            </li>
            <li>
              <JobFeature>
                <strong>Compliance:</strong> Ensure compliance with data protection regulations like GDPR and CCPA.
              </JobFeature>
            </li>
            <li>
              <JobFeature>
                <strong>Easy Integration:</strong> REST API, file system, TCP.
              </JobFeature>
            </li>
            <li>
              <JobFeature>
                <strong>Scalable:</strong> Process massive amounts of data.
              </JobFeature>
            </li>
            <li>
              <JobFeature>
                <strong>Flexible Deployment:</strong> Runs on cloud, on-premise, laptops, desktops.
              </JobFeature>
            </li>
            <li>
              <JobFeature>
                <strong>Offline:</strong> No internet connection required for high-security environments.
              </JobFeature>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <Customers />
      </div>
    </section>

    <section className="py-8 py-md-11 bg-black bg-dotted">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-7 text-center">
            <h2 className="display-3 fw-bold font-gradient">Solution Features</h2>
            <p className="lead text-muted mb-9">
              Powerful anonymization capabilities in a single Docker Container.
              <br /> Read more in the{" "}
              <a href="https://doc.celantur.com/container/getting-started" target="_blank">
                documentation
              </a>
              .
            </p>
          </div>
        </div>
        <div className="row mt-n7">
          <FeatureCard headline="Blurring" icon={faImagePortrait} size="3x">
            Blur faces, persons, vehicles and license plates on images and videos.
          </FeatureCard>
          <FeatureCard headline="Configurable" icon={faCog} size="3x">
            Define detection thresholds, tiling, blurring appearance and more.
          </FeatureCard>
          <FeatureCard headline="Stateless Docker Container" icon={faBox} size="3x">
            Perfect for deployments on the Cloud.
          </FeatureCard>
          <FeatureCard headline="I/O" icon={faDatabase} size="3x">
            REST API, file system, TCP.
          </FeatureCard>
          <FeatureCard headline="Specify Blur Section" icon={faSquare} size="3x">
            Limit anonymization to a section of the image.
          </FeatureCard>
          <FeatureCard headline="GPU &amp; CPU Support" icon={faMicrochip} size="3x">
            Leverage Nvidia GPU or CPU.
          </FeatureCard>
          <FeatureCard headline="Extract Metadata" icon={faInfoCircle} size="3x">
            Extract bounding boxes and segmentation masks.
          </FeatureCard>
          <FeatureCard headline="Retain Image Metadata" icon={faCode} size="3x">
            Image metadata (EXIF, IPTC, XMP) is retained.
          </FeatureCard>
          <FeatureCard headline="For Linux &amp; Windows" icon={faLinux} size="3x">
            Runs on Linux and Windows (WSL).
          </FeatureCard>
        </div>
      </div>
    </section>

    <section class="mt-8">
      <div class="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 text-center mb-5">
            <h2 className="font-weight-bold">Pricing</h2>
            <p className="font-size-lg text-gray-700">Flexible Pricing for Every Stage of Your Business.</p>
          </div>
        </div>
        <div class="row gx-4">
          <div class="col-12 col-md-4">
            <div class="card shadow-lg mb-6 mb-md-0">
              <div class="card-body">
                <div class="text-center mb-3">
                  <span className="badge badge-success">Basic</span>
                </div>
                <div class="d-flex justify-content-center">
                  <span class="h2 mb-0 mt-2 pr-1 font-weight-bold font-green">€</span>
                  <span class="price display-2 mb-0 font-weight-bold font-green">4,000</span>
                </div>
                <p class="text-center text-body-secondary mb-5">1yr, per machine</p>
                <JobFeature>Anonymize Faces, Persons, License Plates, Vehicles</JobFeature>
                <JobFeature>Process Images and Videos</JobFeature>
                <JobFeature>I/O: File System</JobFeature>
                <a href="#request_demo" className="btn btn-success w-100 event-start-demo">
                  Request Free Demo <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
                </a>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card shadow-blue-green mb-6 mb-md-0">
              <div class="card-body">
                <div class="text-center mb-3">
                  <span className="badge badge-success">Pro</span>
                </div>
                <div class="d-flex justify-content-center">
                  <span class="h2 mb-0 mt-2 pr-1 font-weight-bold font-green">€</span>
                  <span class="price display-2 mb-0 font-weight-bold font-green">6,500</span>
                </div>
                <p class="text-center text-body-secondary mb-5">1yr, per machine</p>
                <JobFeature icon={faArrowLeft} bold="true">
                  <strong>Everything in Basic, plus:</strong>
                </JobFeature>
                <JobFeature>Generate metadata and segmentation masks</JobFeature>
                <JobFeature>I/O: REST API, TCP</JobFeature>
                <a href="#request_demo" className="btn btn-success w-100 event-start-demo">
                  Request Free Demo <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
                </a>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card shadow-blue-green mb-6 mb-md-0">
              <div class="card-body">
                <div class="text-center mb-3">
                  <span className="badge badge-primary">Enterprise</span>
                </div>

                <div class="d-flex justify-content-center">
                  <span class="h2 mb-0 mt-2 pr-1 font-blue">€</span>
                  <span class="price display-2 mb-0 mb-0 font-weight-bold font-blue">15,000</span>
                </div>

                <p class="text-center text-body-secondary mb-5">Starting from</p>

                <JobFeature icon={faArrowLeft} bold="true">
                  <strong>Everything in Pro, plus:</strong>
                </JobFeature>
                <JobFeature>Deploy Unlimited Instances in the Cloud</JobFeature>
                <JobFeature>Enterprise Support/SLA</JobFeature>
                <JobFeature>Evaluation of Custom Reference Datasets</JobFeature>
                <JobFeature>Certifiable for AI Act and GDPR</JobFeature>

                <Link to="/contact/" className="btn btn-primary w-100 event-start-demo">
                  Contact Sales <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <a id="request_demo"></a>
        <ActionForm
          source="container"
          headline="Request a Free Demo."
          placeholderInput="Tell us a bit about your use case. This will help us to getting you up to speed."
          labelSubmitButton="Request Demo"
        />
      </div>
    </section>

    <section className="my-8">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 text-center mb-5">
            <h2 className="font-weight-bold">Success Stories</h2>
            <p className="font-size-lg text-gray-700">
              Read how industry-leading companies use Celantur Container to solve their privacy challenges.
            </p>
          </div>
        </div>
        <div className="row">
          <SuccessStoryCard
            title={
              <>
                <mark>greehill</mark> provides Green Asset Management without privacy concerns
              </>
            }
            link="/success-story-greehill/"
            image={data.greehillPointcloud.childImageSharp.fluid}
          />
          <SuccessStoryCard
            title={
              <>
                <mark>Fortune 500 tech company</mark> develops L4 self-driving vehicle technology in a GDPR-compliant
                manner
              </>
            }
            link="/success-story-fortune-500-self-driving/"
            image={data.traffic.childImageSharp.fluid}
          />
        </div>
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <FeatureObjectTypes />
      </div>
    </section>

    <section className="py-8">
      <div className="container">
        <h2 className="font-weight-bold text-center">
          Easy to <span className="text-success">integrate</span>
        </h2>
        <FeatureContainerApi />
        <FeatureTcpNumpy />
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <FeatureMetadataMasks />
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <ActionForm
          source="container"
          headline="Questions?"
          placeholderInput="Tell us a bit about your use case. This will help us to getting you up to speed."
          labelSubmitButton="Send"
        />
      </div>
    </section>
  </Layout>
)

export default ProductContainer

export const logo = graphql`
  fragment imageProductPage on File {
    childImageSharp {
      fluid(maxWidth: 640) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    greehillPointcloud: file(relativePath: { eq: "greehill-pointcloud.png" }) {
      ...imageProductPage
    }
    traffic: file(relativePath: { eq: "traffic.jpg" }) {
      ...imageProductPage
    }
    headerImage: file(relativePath: { eq: "container-product.png" }) {
      ...imageProductPage
    }
  }
`
