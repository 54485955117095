import React from "react"

const FeatureContainerApi = () => {
  const codePost = `'/v1/file?method=blur&face=true' 'fileobject=@/path/to/image.jpg'`
  const codePostResponse = `{ "content-type": "image/jpeg",
        "id": {image_id},
        "metadata-url": "/v1/file/{image_id}/metadata",
        "binary-mask-url": "/v1/file/{image_id}/binary-mask",
        "instance-mask-url": "/v1/file/{image_id}/instance-mask",
        "anonymised-url": "/v1/file/{image_id}/anonymised" }`

  return (
    <>
      <div className="row align-items-center my-11">
        <div className="col-12 col-md-6 col-lg-5">
          <h3 className="font-weight-bold">
            <span className="badge bg-warning">POST</span> and <span className="badge bg-warning">GET</span> data via{" "}
            <span className="text-success">REST API</span>
          </h3>
          <p className="font-size-lg text-gray-700">Seamless integration into your data workflows via REST API.</p>
          <p>
            <a href="https://doc.celantur.com/container/usage/rest-api-v1-mode" target="_blank" rel="noreferrer">
              ❯ API Endpoints
            </a>
          </p>
          <p>
            <a
              href="https://doc.celantur.com/container/usage/rest-api-v1-mode#examples"
              target="_blank"
              rel="noreferrer"
            >
              ❯ API Examples
            </a>
          </p>
        </div>
        <div className="col-12 col-md-6 col-lg-7">
          <div className="card shadow-lift mb-5">
            <div className="card-body">
              <span className="badge bg-warning badge-float badge-float-outside">POST Request</span>
              <code>{codePost}</code>
            </div>
          </div>
          <div className="card shadow-lift mb-5">
            <div className="card-body">
              <span className="badge bg-light badge-float badge-float-outside">Response</span>
              <code>{codePostResponse}</code>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FeatureContainerApi
