import React from "react"
import { Link } from "gatsby"

const FeatureTcpNumpy = () => {
	return (
		<>
			<div className="row align-items-center my-11">
				<div className="col-12 col-md-6 col-lg-4">
					<h3 className="font-weight-bold">
						Read from <span className="text-success">file system</span>
					</h3>
					<p className="font-size-lg text-gray-700">
						Simply specifiy input and output directories.
					</p>
					<p>
						<a
              href="https://doc.celantur.com/container/usage/batch-and-stream-mode"
              target="_blank"
              rel="noreferrer"
            >
              ❯ File System Documentation
            </a>
					</p>
				</div>
				<div className="col-12 col-md-6 col-lg-4">
					<h3 className="font-weight-bold">
						Transfer <span className="text-success">NumPy</span> array via <span className="text-success">TCP</span> socket
					</h3>
					<p className="font-size-lg text-gray-700">
						Send and receive images either as an image or NumPy array via a TCP socket connection.
					</p>
					<p>
						<a
              href="https://doc.celantur.com/container/usage/tcp-mode"
              target="_blank"
              rel="noreferrer"
            >
              ❯ TCP Mode Documentation
            </a>
					</p>
				</div>
				<div className="col-12 col-md-6 col-lg-4">
					<h3 className="font-weight-bold">
						Fetch from <span className="text-success">API</span>
					</h3>
					<p className="font-size-lg text-gray-700">
						Let Celantur Container read/write data from queues.
					</p>
					<p>
						<a
              href="https://doc.celantur.com/container/usage"
              target="_blank"
              rel="noreferrer"
            >
              ❯ API Fetch Documentation
            </a>
					</p>
				</div>
			</div>
		</>
	)
}

export default FeatureTcpNumpy
