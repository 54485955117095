import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

const FeatureObjectTypes = ({ data }) => {
  return (
    <>
      <div className="row align-items-center justify-content-between my-8 mb-md-11">
        <div className="col-12 col-md-6 order-md-2">
          <h2 className="font-weight-bold"><span className="text-primary">Anonymize</span> personal data</h2>

          <p className="font-size-lg text-muted mb-6">
            Automatically remove personal identifiable information (PII), to protect
            individuals' privacy.
          </p>

          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="d-flex">
                <div className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "#4285f4" }}
                  />
                </div>

                <p className="text-primary font-weight-bold">Faces</p>
              </div>

              <div className="d-flex">
                <div className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "#4285f4" }}
                  />
                </div>

                <p className="mb-lg-0 text-primary font-weight-bold">Bodies</p>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="d-flex">
                <span className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "#4285f4" }}
                  />
                </span>

                <p className="text-primary font-weight-bold">License Plates</p>
              </div>

              <div className="d-flex">
                <div className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "#4285f4" }}
                  />
                </div>

                <p className="mb-0 text-primary font-weight-bold">Vehicles</p>
              </div>
            </div>
          </div>

          <p className="font-size-sm text-muted mt-5">
            You need other object types anomymized? <Link to="/contact/" className="event-contact">Let us know.</Link>
          </p>
        </div>
        <div className="col-12 col-md-6 col-lg-5 order-md-1">
          <Img className="rounded mx-auto d-block mt-5" fluid={data.imageObjectTypes.childImageSharp.fluid} />
        </div>
      </div>
    </>
  )
}

const objectTypes = props => (
  <StaticQuery
    query={graphql`
      query {
        imageObjectTypes: file(relativePath: { eq: "blurringObjectTypes.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `}
    render={data => <FeatureObjectTypes data={data} {...props} />}
  />
)

export default objectTypes;