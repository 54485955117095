import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const FeatureMetadataMasks = ({ data }) => {
  const codeMetadata = `{ "id": "image-name.jpg",
        "detections": [ {
            "id": 0,
            "offset": [1586, 776],
            "bbox": [1586, 776, 3094, 3453],
            "score": 0.9994864463806152,
            "type_label": "person",
            "color": [128, 85, 0], ...
          }, ...`

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 text-center my-8">
          <h2 className="font-weight-bold">
            Extract <span className="text-success">Metadata</span> and{" "}
            <span className="text-success">Segmentation Masks</span>
          </h2>
          <p className="font-size-lg text-gray-700">
            Metadata and Masks extracted from processed images can be ingested into your data workflow.
            <br />
            <a
              href="https://doc.celantur.com/container/usage/segmentation-masks-and-metadata"
              target="_blank"
              rel="noreferrer"
            >
              ❯ Segmentation Mask Documentation
            </a>
          </p>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-12 col-md-6 col-lg-4">
          <div className="card shadow-dark mb-5">
            <span className="badge bg-light badge-float badge-float-outside">Anonymized Image</span>
            <Img className="rounded d-block" fluid={data.imageAnonymized.childImageSharp.fluid} />
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <div className="card shadow-dark mb-5">
            <div className="card-body">
              <span className="badge bg-light badge-float badge-float-outside">JSON Metadata</span>
              <code>{codeMetadata}</code>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <div className="card shadow-dark mb-5">
            <span className="badge bg-light badge-float badge-float-outside">Binary Segmentation Mask</span>
            <Img className="rounded d-block" fluid={data.imageBinaryMask.childImageSharp.fluid} />
          </div>
          <div className="card shadow-dark mb-5">
            <span className="badge bg-light badge-float badge-float-outside">Instance Segmentation Mask</span>
            <Img className="rounded d-block" fluid={data.imageInstanceMask.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </>
  )
}

const metadata = (props) => (
  <StaticQuery
    query={graphql`
      query {
        imageAnonymized: file(relativePath: { eq: "doc/container-api-example-pixelated.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 360, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        imageBinaryMask: file(relativePath: { eq: "doc/container-api-example-binary-mask.png" }) {
          childImageSharp {
            fluid(maxWidth: 360, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        imageInstanceMask: file(relativePath: { eq: "doc/container-api-example-instance-mask.png" }) {
          childImageSharp {
            fluid(maxWidth: 360, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `}
    render={(data) => <FeatureMetadataMasks data={data} {...props} />}
  />
)

export default metadata
